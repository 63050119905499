<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-card class="authBox">
                    <div slot="header">
                        <span>已有角色</span>
                    </div>
                    <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar reviewBookList" round align="left"
                        ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                        :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='treeLoading'
                        :data="existedAuthList">
                        <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                        <vxe-column field="label" title="角色名" width="200" :show-overflow="'tooltip'"></vxe-column>
                        <vxe-column title="操作" min-width="200">
                            <template v-slot="scope" #default="{ row }">
                                <!-- click触发 -> 后端调数据 -> 前端显示 index/vxe-modal -->
                                <el-button size="small" type="text" @click="viewWebRoleAuthority(scope.row)">查看</el-button>
                                <el-popconfirm title="是否确认保存？" @confirm="changeWebRoleAuthority(scope.row)">
                                    <el-button size="small" type="text" slot="reference" style="margin: 0 5px;">保存</el-button>       
                                </el-popconfirm>
                                <el-popconfirm title="是否确认删除？" @confirm="deleteWebRoleAuthority(scope.row)">
                                    <el-button size="small" type="text" slot="reference">删除</el-button>
                                </el-popconfirm>
                                <!-- <el-button size="small" type="text">删除</el-button> -->
                            </template>
                        </vxe-column>
                    </vxe-table>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="authBox selectedAuth">
                    <div slot="header" class="clearfix">
                        <span>角色权限</span>
                    </div>
                    <div>
                        <el-tree :data="data" show-checkbox :check-strictly="checkStrictly" ref="tree" node-key="id" :props="defaultProps"
                        @check-change="selectedChange" ></el-tree>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                formData: {
                    policyName: '',
                    note: ''
                },
                data: [],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                existedAuthList: [{
                    id:1 ,
                    label: 'A'
                }],
                defaultCheckedKeysList: [25,26,27],
                checkStrictly: true,
                treeLoading: true,
                selected:[],
                selectedKeysList:[]
            }
        },
        props: ['roleId'],
        created() {
            this.getExistedAuthList();
            this.getAuthoritySelect();
        },
        methods: {
            // 获取角色清单
            getExistedAuthList(){
                this.$api.authority.getExistedAuthList()
                .then(res =>{
                    if(res.data.code == 200){
                        this.existedAuthList = res.data.data;
                    }else{
                        this.$message.warning('服务器维护');
                    }
                })
            },
            // 获取权限清单
            getAuthoritySelect() {
                let params = {
                    role_id: this.roleId
                };
                console.log(params);
                this.$api.authority.getAuthoritySelect(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.data = res.data.data;
                            this.treeLoading = false;
                        }
                    }).catch(err => {
                        this.$message.warning('服务器维护');
                    })
            },
            // 查看角色权限
            viewWebRoleAuthority(row){
                console.log(row);
                let params = {
                    "webRoleNames": row.label
                }
                this.checkStrictly = true;
                this.$api.authority.getDefaultCheckedKeysList(params)
                    .then(res => {
                        if(res.data.code == 200){
                            this.defaultCheckedKeysList = res.data.data;
                            this.$refs.tree.setCheckedKeys(this.defaultCheckedKeysList);
                            this.checkStrictly = false
                        }
                    }).catch(err => {
                        this.$message.warning('服务器维护');
                    })
            },
            // 勾选权限
            selectedChange(p1, p2, p3) {
                console.log(this.$refs.tree.getCheckedNodes(false,true));
                this.selected = this.$refs.tree.getCheckedNodes(false,true);
                // this.selected = this.$refs.tree.getCheckedNodes().filter(
                //     (item) => {
                //         // console.log(item);
                //         // return !item.hasOwnProperty('children');
                //         return item['children'];
                //         // return !item['children'];
                //     }
                // );
                console.log(this.selected);
            },

            // 修改角色权限
            changeWebRoleAuthority(row){
                console.log(row);
                this.selectedChange();
                this.selectedKeysList = this.selected.map(item => item.id);
                let insertKeysList = this.selectedKeysList.filter(item => this.defaultCheckedKeysList.indexOf(item) == -1);
                let deleteKeysList = this.defaultCheckedKeysList.filter(item => this.selectedKeysList.indexOf(item) == -1);
                let params = {
                    "webRoleId": row.id,
                    "insertKeysList": insertKeysList,
                    "deleteKeysList": deleteKeysList
                }
                console.log(params);
                this.$api.authority.changeDefaultCheckedKeysList(params)
                    .then(res => {
                        if(res.data.code == 200){
                            this.$message.success('修改成功');
                        }
                    }).catch(err => {
                        this.$message.warning('服务器维护');
                    })
            },
            // 删除角色
            deleteWebRoleAuthority(row){
                if(row.label == '默认角色'){
                    this.$message.warning('该角色为系统默认角色，禁止删除！')
                }else{
                    let params = {
                        "webRoleId": row.id
                    }
                    this.$api.authority.deleteWebRoleAuthority(params)
                        .then(res => {
                            if(res.data.code == 200){
                                this.$message.success('删除成功');
                                this.$refs.tree.setCheckedKeys([]);
                                this.getExistedAuthList();
                            }
                        }).catch(err => {
                            this.$message.warning('服务器维护');
                        })
                }
            }
        },
        components: {

        }
    }
</script>

<style scoped>

</style>

<style>
    /* .selectedAuth .el-card__body {
        padding: 0px;
        overflow: auto;
    }

    .authBox .el-card__body {
        height: calc(100vh - 350px);
        overflow: auto;
    } */
</style>